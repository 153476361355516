<template>
  <div class="timeline-wrapper">
    <canvas id="timeline-chart" ref="chart"></canvas>
    <div class="select" ref="select"></div>
    <div class="hover" ref="hover"></div>
    <div class="tooltip" ref="tooltip"></div>
  </div>
</template>
<style scoped>
  .timeline-wrapper {
    position: relative;
    width: 100%;
    height: 250px;
  }
  .tooltip {
    position: absolute;
    background: #333;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    display: none;
    top: 0px;
    left: 0px;
    pointer-events: none;
  }

  .hover {
    position: absolute;
    width: 1px;
    height: 186px;
    top: 32px;
    left: 0px;
    background: rgba(100,100,100, 1);
    pointer-events: none;
  }

  .select {
    position: absolute;
    width: 3px;
    height: 186px;
    top: 32px;
    left: 0px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(33, 150, 243, 1) 0px, rgba(33, 150, 243, 1) 1px, rgba(255,255,255,1) 1px, rgba(255,255,255,1) 2px, rgba(33, 150, 243, 1) 2px, rgba(33, 150, 243, 1) 3px);
    pointer-events: none;
  }
</style>
<script>
import { getDateFormatter } from '../formatUtils.js';
import { calcDays } from '../rkiDataUtils';
import Chart from 'chart.js';
const log10 = Chart.helpers.math.log10;

function niceNum(range) {
  const exponent = Math.floor(log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (fraction <= 1.0) {
    niceFraction = 1;
  } else if (fraction <= 2) {
    niceFraction = 2;
  } else if (fraction <= 5) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}

export default {
  name: 'TimeLine',
  props: {
    data: {
      type: Array
    },
    name: {
      type: String
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    dayIndex: {
      type: Number
    }
  },
  data() {
    return {
    }
  },
  watch: {
    data() {
      this.draw();
    },
    startDate() {
      this.draw();
    },
    endDate() {
      this.draw();
    },
    dayIndex() {
      this.updateDayIndex(this.dayIndex);
    }
  },
  beforeDestroy() {
    this.$refs.chart.removeEventListener('mousedown', this.downHandler);
    this.$refs.chart.removeEventListener('mouseup', this.upHandler);
    this.$refs.chart.removeEventListener('mousemove', this.elMoveHandler);
    this.$refs.chart.removeEventListener('mouseout', this.outHandler);
    this.$refs.chart.removeEventListener('touchstart', this.touchStart);
    this.$refs.chart.removeEventListener('touchend', this.touchEnd);
    this.$refs.chart.removeEventListener('touchcancel', this.touchEnd);
    this.$refs.chart.removeEventListener('touchmove', this.touchMove);
  },
  mounted() {
    const el = this.$refs.chart;
    const ctx = el.getContext('2d');
    el.width = this.$el.clientWidth;
    el.height = this.$el.clientHeight;

    this.state = 'hover';
    this.$refs.hover.style.display = 'none';

    this.dateFormatter = getDateFormatter();

    this.chart = new Chart(ctx, {
        type: 'line',
        data: {
            datasets: [{
              data: [],
              pointRadius: 0,
              backgroundColor: 'rgba(33, 150, 243, 0.8)'
            }],
        },
        options: {
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            tooltips: {
                mode: 'x-axis',
                enabled: false,
                custom: (tooltipModel) => {
                  if(tooltipModel.dataPoints) {
                    const dataPoint = tooltipModel.dataPoints[0];
                    this.$refs.tooltip.innerHTML = this.dateFormatter.format(new Date(dataPoint.label)) + ": " + Math.round(parseFloat(dataPoint.value));
                    this.$refs.tooltip.style.display = 'block';
                    const rect = this.$refs.tooltip.getBoundingClientRect();
                    const toLeft = dataPoint.x > this.$refs.chart.clientWidth / 2 ;
                    this.$refs.tooltip.style.top = `${Math.round(32 + (186 - rect.height) / 2)}px`;
                    this.$refs.tooltip.style.left = `${Math.round(toLeft ? (dataPoint.x - rect.width - 10) : (dataPoint.x + 10))}px`;
                  } else {
                    this.$refs.tooltip.style.display = 'none';
                  }
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    bounds: 'data',
                    time: {
                        unit: 'month'
                    }
                }],
                yAxes: [{
                    ticks: {
                        min: 0,
                    }
                }]
            }
        }
    });
    this.draw();
    el.addEventListener('mousedown', this.downHandler);
    el.addEventListener('mousemove', this.elMoveHandler);
    el.addEventListener('mouseout', this.outHandler);
    el.addEventListener('touchstart', this.touchStart);
    el.addEventListener('touchend', this.touchEnd);
    el.addEventListener('touchcancel', this.touchEnd);
    el.addEventListener('touchmove', this.touchMove);

  },
  methods: {
    touchStart(evt) {
      this.state = 'drag';
      this.outHandler();
      this.updateSelect(evt);
    },
    touchMove(evt) {
      this.moveHandler(evt);
    },
    touchEnd() {
      this.state = 'hover';
      this.outHandler();
    },
    downHandler(evt) {
      this.state = 'drag';
      this.outHandler();
      window.addEventListener('mouseup', this.upHandler);
      window.addEventListener('mousemove', this.moveHandler);
      this.updateSelect(evt);
    },
    upHandler(evt) {
      this.state = 'hover';
      window.removeEventListener('mouseup', this.upHandler);
      window.removeEventListener('mousemove', this.moveHandler);
      this.elMoveHandler(evt);
    },
    moveHandler(evt) {
      if(evt.target.getBoundingClientRect) {
        this.updateSelect(evt);
      }
    },
    outHandler() {
      this.$refs.hover.style.display = 'none';
      this.$refs.tooltip.style.display = 'none';
    },
    elMoveHandler(evt) {
      if(this.state === 'hover') {
        const element = this.chart.getElementsAtXAxis(evt)[0];
        if(element) {
          this.$refs.hover.style.display = 'block';
          this.$refs.hover.style.left = `${element._view.x}px`;
        }
      } else {
        this.$refs.hover.style.display = 'none';
      }
    },
    updateDayIndex(index) {
      const data = this.chart.getDatasetMeta(0).data;
      const item = data[index];
      this.$refs.select.style.display = 'block';
      this.$refs.select.style.left = `${item._view.x - 1}px`;
    },
    updateSelect(evt) {
      const element = this.chart.getElementsAtXAxis(evt)[0];
      if(element) {
        this.$emit('select', element._index);
      }
    },
    calcMinMax() {
      let min = Infinity;
      let max = -Infinity;
      // calc y ticks min / max
      for(const item of this.data) {
        if(item.t >= this.startDate && item.t <= this.endDate) {
          min = Math.min(item.y, min);
          max = Math.max(item.y, max);
        }
      }
      return {
        min,
        max
      }
    },
    async draw() {
      const max = Math.ceil(this.calcMinMax().max);
      const step = niceNum(max) / 10;
      const remainder = max % step;
      this.chart.options.scales.yAxes[0].ticks.min = 0;
      this.chart.options.scales.yAxes[0].ticks.max = max + step - remainder;
      this.chart.options.scales.xAxes[0].ticks.min = this.startDate;
      this.chart.options.scales.xAxes[0].ticks.max = this.endDate;
      this.chart.data.datasets[0].label = this.name;
      this.chart.data.datasets[0].data = this.data;
      const days = calcDays(this.endDate, this.startDate);
      this.chart.options.scales.xAxes[0].time.unit = days < 100 ? 'day' : 'month';
      this.chart.update();
      this.updateDayIndex(this.dayIndex);
    }
  }
}
</script>
