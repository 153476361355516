<template>
  <!--<keep-alive>-->
  <component :is="$mq === 'lg' ? 'LayoutWide' : 'LayoutSmall'" :loaded="loaded">
    <template #title>
      {{title}}
    </template>
    <template #options>
      <!--
      <Field label="Age Range" v-if="ageGroups.length > 1">
        <div class="p-d-flex p-ai-center p-jc-center">
          <label class="p-mx-2 p-my-2 date-label left">{{ageRangeLabel(0)}}</label>
          <Slider class="p-mx-3" v-model="ageRange" @slideend="updateSelectedAgeGroups()" :range="true" :min="0" :max="ageGroups.length - 2"/>
          <label class="p-ml-2 p-mr-2 p-my-2 date-label right">{{ageRangeLabel(1)}}</label>
        </div>
      </Field>
    -->
      <template v-if="selectedTimeline !== null">
        <Field label="Time Range">
          <div class="p-d-flex p-ai-center p-jc-center">
            <label class="p-mx-2 p-my-2 date-label left">{{dateFormatter.format(startDate)}}</label>
            <Slider class="p-mx-3" v-model="timeSpanIdx" @slideend="updateMaxValue()" :range="true" :min="0" :max="maxDayIndex"/>
            <label class="p-ml-2 p-mr-2 p-my-2 date-label right">{{dateFormatter.format(endDate)}}</label>
          </div>
        </Field>
        <Divider />
      </template>
    </template>
    <template #map>
      <CoronaMap
        ref="map"
        :selectedId="selectedKreisId"
        @select="updateSelection($event)"
        @mapReady="$refs.map.data = kreise"
        :attributionList="getAttribution()"
        :colorMap="selectedColorMap.id"
        :mode="(selectedColorMap.id === 'magma') ? 'dark' : 'light'"
        :center="center"
        :zoom="zoom"
      />
    </template>
    <template #regiontitle>
      <Dropdown
        style="width:100%;"
        :value="selectedRegion"
        @input="updateSelection($event)"
        :options="availableRegions"
        optionLabel="label"
        :filter="true"
        placeholder="Select a Country">
      </Dropdown>
    </template>
    <template #dayselect>
      <div class="p-d-flex p-ai-center p-jc-center">
        <Calendar id="icon" v-model="currentDate" :showIcon="true" :minDate="startDate" :maxDate="endDate" :manualInput="false" :dateFormat="getLocaleDateString()" :style="{ flex: '1 1 auto'}" />
        <Button @click="lastDay()" :disabled="dayIndex <= timeSpanIdx[0]" icon="pi pi-angle-left" class="p-ml-2 p-button-sm p-button-rounded p-button-primary" :style="{ flex: '0 0 auto'}" />
        <Button @click="nextDay()" :disabled="dayIndex >= timeSpanIdx[1]" icon="pi pi-angle-right" class="p-ml-1 p-button-sm p-button-rounded p-button-primary" :style="{ flex: '0 0 auto'}" />
      </div>
      <Slider class="p-my-3" v-model="dayIndex" :min="timeSpanIdx[0]" :max="timeSpanIdx[1]"/>
    </template>
    <template #timeline>

      <MiniTimeLine
        :data="selectedTimeline"
        :name="selectedName"
        :startDate="startDate"
        :endDate="endDate"
        :dayIndex="dayIndex"
        :colorMap="selectedColorMap.id"
        :maxValue="maxValue"
      />
    </template>
    <template #legend>
      <Legend
        :maxValue="maxValue"
        :selectedName="selectedName"
        :selectedValue="selectedValue"
        :colorMap="selectedColorMap"
        @colormap="selectedColorMap = $event"
        :barWidth="400"
      />
    </template>
  </component>
  <!--</keep-alive>-->
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint-disable no-unused-vars */
import Vue from 'vue';
import Pbf from 'pbf';
import geobuf from 'geobuf';
import Card from 'primevue/card';
import Button from 'primevue/button';
import MultiSelect from 'primevue/multiselect';
import Calendar from 'primevue/calendar';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown/Dropdown';
import Slider from './components/Slider.vue';
import rki from './pbf_models/rki_days.js';
import RKIDataHolder from './RKIDataHolder.js';
import { getDateFormatter, getLocaleDateString } from './formatUtils.js';
import { currentLocation } from './route.js';
import {
  calcDays,
  calc7Day,
  calcTimeline,
  calcMaxValue,
  calcEWZAll,
  oneDay
} from './rkiDataUtils.js';

export default {
  name: 'App',
  props: {
    shapeURL: {
      type: String,
      default: 'data/world.pbf'
    },
    dataURL: {
      type: String,
      default: 'data/world_days.pbf'
    },
    title: String,
    datasetLabel: String,
    center: {
      type: Array,
      default() { return [11, 48.2324] } ,
    },
    zoom: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      loaded: false,
      timeSpanIdx: [0, 1],
      startDayIdx: 0,
      endDayIdx: 0,
      dayIndex: 0,
      minDate: 0,
      maxDayIndex: 100,
      maxValue: 600,
      selectedName: "",
      selectedKreisId: NaN,
      selectedEWZ: NaN,
      ageGroups: [],
      ageRange: [0, 1],
      groupIds: [],
      selectedRegion: null,
      selectedColorMap: { label: 'Magma', id: 'magma'},
    }
  },
  async mounted() {
    this.dateFormatter = getDateFormatter();

    console.log('formatter:', getLocaleDateString());
    console.log('load data', this.shapeURL, this.dataURL);

    console.time('load data');
    const remoteData = await Promise.all([
      fetch(this.shapeURL),
      fetch(this.dataURL),
    ]);

    const remoteBufferData = await Promise.all([
      remoteData[0].arrayBuffer(),
      remoteData[1].arrayBuffer(),
    ]);
    console.timeEnd('load data');
    console.time('parse data');
    const kreisePbf = new Pbf(remoteBufferData[0]);
    const pbf = new Pbf(remoteBufferData[1]);
    const rkiData = rki.Dataset.read(pbf);
    this.kreise = geobuf.decode(kreisePbf);
    this.availableRegions = this.getAvailableRegions(this.kreise);
    this.dataHolder = new RKIDataHolder(rkiData);
    console.timeEnd('parse data');
    const ageGroups = [];
    const groupIds= [];

    for(let i = 0; i < rkiData.groups.length; i++) {
      const group = rkiData.groups[i];
      ageGroups.push({label: group });
      groupIds.push(i);
    }

    this.ageGroups = ageGroups;
    this.groupIds = groupIds;
    this.ageRange = [0, this.ageGroups.length - 2];
    this.minDate = this.dataHolder.minDate;
    this.maxDayIndex = calcDays(this.dataHolder.minDate, this.dataHolder.maxDate);
    this.timeSpanIdx = [0, this.maxDayIndex];
    this.dayIndex = this.maxDayIndex;
    this.updateMaxValue();
    this.ewzAll = calcEWZAll(this.kreise);
    this.updateSelection(null);

    this.loaded = true;

    Vue.nextTick(() => {
      if(this.$refs.map) {
        this.$refs.map.data = this.kreise;
      }
      this.updateMap();
    });
  },
  computed: {
    currentDate: {
      get() {
        return new Date(Number(this.minDate) + oneDay * this.dayIndex);
      },
      set(date) {
        this.dayIndex = calcDays(this.minDate, date);
      }
    },
    startDate() {
      return new Date(Number(this.minDate) + oneDay * this.timeSpanIdx[0]);
    },
    endDate() {
      return new Date(Number(this.minDate) + oneDay * this.timeSpanIdx[1]);
    },
    selectedValue() {
      if(this.selectedTimeline && this.dataHolder) {
        const timeLineIdx = this.dataHolder.getDateIdx(this.currentDate);
        if(timeLineIdx !== undefined) {
          return Math.round(this.selectedTimeline[timeLineIdx].y);
        } else {
          return 0;
        }
      }
      return NaN;
    },
    selectedTimeline() {

      console.log('compute selectedTimeline', this.selectedEWZ, this.selectedKreisId);
      if(!isNaN(this.selectedEWZ) && this.selectedKreisId !== -1) {
        return calcTimeline(this.dataHolder, {
          kreisId: this.selectedKreisId,
          ewz: this.selectedEWZ,
          groupIds: this.groupIds,
          startDate: this.dataHolder.minDate,
          endDate: this.dataHolder.maxDate
        });
      } else if(this.selectedKreisId === -1) {
        return calcTimeline(this.dataHolder, {
          ewz: this.ewzAll,
          groupIds: this.groupIds,
          startDate: this.dataHolder.minDate,
          endDate: this.dataHolder.maxDate
        });
      } else {
        return null;
      }
    },
  },
  watch: {
    currentDate() {
      this.updateMap();
    },
    timeSpanIdx() {
      this.dayIndex = Math.min(Math.max(this.dayIndex, this.timeSpanIdx[0]), this.timeSpanIdx[1]);
    },
    selectedColorMap() {
      localStorage.setItem('colormap', this.selectedColorMap.id);
    }
  },
  methods: {
    getAvailableRegions(featureCollection) {
      const res = featureCollection.features.map((v) => v.properties);
      res.push({
        label: this.datasetLabel,
        id: -1,
        ezw: NaN
      });
      res.sort((a, b) => {
        return a.label.localeCompare(b.label);
      });
      return res;
    },
    getAttribution() {
      return currentLocation() === 'germany' ? [
        '<a href="https://www.bkg.bund.de/DE/Home/home.html" target="_blank">© GeoBasis-DE / BKG 2019</a>',
        '<a href="https://www.rki.de/DE/Home/homepage_node.html" target="_blank">Robert Koch-Institut (RKI), dl-de/by-2-0</a>'
      ] : [];
    },
    getLocaleDateString,
    getWidth() {
      return screen.width;
    },
    lastDay() {
      if(this.dayIndex > 0) {
        this.dayIndex--;
      }
    },
    nextDay() {
      if(this.dayIndex < this.maxDayIndex) {
        this.dayIndex++;
      }
    },
    updateMap() {
      const currentValues = calc7Day(this.dataHolder, this.currentDate, this.groupIds);

      // update geojson featurelist
      for(const item of this.kreise.features) {
        const kreisId = Number(item.properties.id);
        const value = currentValues[kreisId];
        if(value !== undefined) {
          const ewz100 = Number(item.properties.ewz) / 100000;
          const valRel = (value / ewz100);
          item.properties.t = valRel / this.maxValue;
          item.properties.vR = valRel;
          item.properties.vA = value;
        } else {
          item.properties.t = 0;
          item.properties.vR = 0;
          item.properties.vA = 0;
        }
      }
      if(this.$refs.map) {
        this.$refs.map.updateMap();
      }
    },
    updateSelection(v) {
      if(!v) {
        this.selectedName = this.datasetLabel;
        this.selectedKreisId = -1;
        this.selectedEWZ = NaN;
      } else {
        this.selectedName = v.label;
        this.selectedKreisId = v.id;
        this.selectedEWZ = v.ewz;
      }
      this.selectedRegion = this.availableRegions.find(v => (v.id === this.selectedKreisId));
    },
    updateMaxValue() {
      console.time('calcMax');
      this.maxValue = calcMaxValue(this.dataHolder, this.kreise, this.groupIds, this.timeSpanIdx[0], this.timeSpanIdx[1]);
      console.timeEnd('calcMax');
      this.updateMap();
    },
    updateSelectedAgeGroups() {
      const groupIds = [];
      const min = this.ageRange[0];
      const max = this.ageRange[1];
      for(let i = 0; i < this.ageGroups.length; i++) {
        if((min <= i && i <= max) || i === (this.ageGroups.length - 1)) {
          groupIds.push(i);
        }
      }
      this.groupIds = groupIds;
      this.updateMaxValue();
    },
    ageRangeLabel(index) {
      const items = this.ageGroups[this.ageRange[index]].label.split('-');
      let label;
      if(index === 0 || items.length === 1) {
        label = items[0].substring(1);
      } else {
        label = items[1].substring(1);
      }
      const labelNum = Number(label);
      return isNaN(labelNum) ? label : String(labelNum);
    }
  },
  components: {
    Card,
    Button,
    MultiSelect,
    Calendar,
    Divider,
    Dropdown
  }

}
</script>
<style scoped>

.selected {
  font-weight: bold;
  text-align: center;
  flex: 1 0 auto;
}

.date-label {
  width: 4rem;
}
.left {
  text-align: left;
}
.right {
  text-align: right;
}
.legend-card {
  position: absolute;
  top: 25px;
  left: 25px;
}

.dayfield {
  font-family: 'monospace';
}
.p-slider-horizontal, .p-inputtext {
	flex-grow: 1;
}

.p-slider-vertical {
	height: 14rem;
}
/deep/.p-card-body {
  height: 100%;
}
/deep/.p-card-content {
  height: 100%;
  padding: 0px;
}

</style>
<style>

@media screen and (min-width: 600px) {
  html, body {
    height: 100%;
    margin: 0;
  }
}
</style>