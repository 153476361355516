import {
  calcDays,
  oneDay
} from './rkiDataUtils';

export default class RKIDaysDataHolder {

  static formatDay(date) {
    const m = (date.getMonth() + 1).toString().padStart(2, '0');
    const d = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}/${m}/${d}`;
  }


  constructor(rkiData) {
    this.ids = rkiData.ids;
    this.groups = rkiData.groups;
    this.dataSets = rkiData.dataSets;
    // we assume sorted dates
    this.minDate = rkiData.dates[0];
    this.maxDate = rkiData.dates[rkiData.dates.length - 1];

    this.numDays = calcDays(this.minDate, this.maxDate) + 1;

    this.groupLength = rkiData.dataSets.length;
    this.dateLength = rkiData.groups.length * this.groupLength;
    this.idLength = this.numDays * this.dateLength;

    // get a continuous array for all days without gaps
    this.dateHash = {};
    this.dates = new Array(this.numDays);
    this.data = new Uint32Array(rkiData.ids.length * this.idLength);

    // generate a hash of the days defined in the rkiData
    const rkiDateHash = {};
    for(let i = 0; i < rkiData.dates.length; i++) {
      const day = RKIDaysDataHolder.formatDay(new Date(rkiData.dates[i]));
      rkiDateHash[day] = i;
    }
    // fill data array and add 0 for gaps not defined in the rkiData
    this.dateHash = {};
    for(let i = 0; i < this.numDays; i++) {
      const date = new Date(this.minDate + i * oneDay);
      const day = RKIDaysDataHolder.formatDay(date);
      const dateIdx = rkiDateHash[day];
      this.fillDate(rkiData, dateIdx, i, this.data);
      this.dates[i] = date.getTime();
      this.dateHash[day] = i;
    }
  }

  fillDate(rkiData, rkiDateIdx, targetDateIdx, data) {
    const groupLength = rkiData.dataSets.length;
    const dateLength = rkiData.groups.length * this.groupLength;
    const idLength = rkiData.dates.length * this.dateLength;
    for(let id = 0; id < rkiData.ids.length; id++) {
      for(let group = 0; group < rkiData.groups.length; group++) {
        for(let dataSet = 0; dataSet < rkiData.dataSets.length; dataSet++) {
          const idx = this.getIdx(id, targetDateIdx, group, dataSet);
          if(undefined !== rkiDateIdx) {
            data[idx] = rkiData.data[
              id * idLength + rkiDateIdx * dateLength + group * groupLength + dataSet
            ];
          } else {
            data[idx] = 0;
          }
        }
      }
    }
  }

  isDatePresent(date) {
    return undefined !== this.dateHash[RKIDaysDataHolder.formatDay(date)];
  }

  getIdIdx(id) {
    return this.ids.indexOf(id);
  }

  getGroupIdx(group) {
    return this.groups.indexOf(group);
  }

  getDateIdx(date) {
    return this.dateHash[RKIDaysDataHolder.formatDay(date)];
  }

  getIdx(idIdx, dateIdx, groupIdx, dataSetIdx) {
    return idIdx * this.idLength + dateIdx * this.dateLength + groupIdx * this.groupLength + dataSetIdx;
  }

  getItem(idIdx, dateIdx, groupIdx, dataSetIdx) {
    return this.data[idIdx * this.idLength + dateIdx * this.dateLength + groupIdx * this.groupLength + dataSetIdx];
  }

}
