import darkModeLayers from './darkModeLayers';

export default {
  version: 8,
  glyphs: 'font/{fontstack}/{range}.pbf',
  sources: {
    countries: {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
        ]
      }
    },
    labels: {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
        ]
      }
    },
    places: {
      'type': 'geojson',
      'data': {
        'type': 'FeatureCollection',
        'features': [
        ]
      }
    }
  },
  layers: darkModeLayers
}
