export const locations = [
  'world',
  'germany',
  'about',
  'privacy'
];

export function currentLocation() {
  const loc = String(window.location);
  for(const item of locations) {
    if(loc.indexOf(item) !== -1) {
      return item;
    }
  }
  return '';
}
