<template>
  <div class="timeline-wrapper">
    <canvas id="timeline-chart" ref="chart"></canvas>
    <div class="select" ref="select"></div>
    <div class="tooltip" ref="tooltip"></div>
    <div class="hover" ref="hover"></div>

  </div>
</template>
<style scoped>
  .timeline-wrapper {
    position: relative;
    width: 100%;
    height: 180px;
  }
  .tooltip {
    position: absolute;
    background: #333;
    color: #fff;
    border-radius: 5px;
    padding: 5px 10px;
    display: none;
    top: 0px;
    left: 0px;
    pointer-events: none;
  }

  .hover {
    position: absolute;
    width: 1px;
    height: 100px;
    top: 0px;
    left: 0px;
    background: rgba(100,100,100, 1);
    pointer-events: none;
  }

  .select {
    position: absolute;
    width: 3px;
    height: 100px;
    top: 0px;
    left: 0px;
    background: rgb(0,0,0);
    background: linear-gradient(90deg, rgba(33, 150, 243, 1) 0px, rgba(33, 150, 243, 1) 1px, rgba(255,255,255,1) 1px, rgba(255,255,255,1) 2px, rgba(33, 150, 243, 1) 2px, rgba(33, 150, 243, 1) 3px);
    pointer-events: none;
  }
</style>
<script>
import { getDateFormatter } from '../formatUtils.js';
import { calcDays } from '../rkiDataUtils';
import { getSteps, colormap } from '../colorMapUtils.js';
import Chart from 'chart.js';
const log10 = Chart.helpers.math.log10;

function niceNum(range) {
  const exponent = Math.floor(log10(range));
  const fraction = range / Math.pow(10, exponent);
  let niceFraction;

  if (fraction <= 1.0) {
    niceFraction = 1;
  } else if (fraction <= 2) {
    niceFraction = 2;
  } else if (fraction <= 5) {
    niceFraction = 5;
  } else {
    niceFraction = 10;
  }

  return niceFraction * Math.pow(10, exponent);
}

export default {
  name: 'TimeLine',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    startDate: {
      type: Date
    },
    endDate: {
      type: Date
    },
    dayIndex: {
      type: Number
    },
    colorMap: {
      type: String
    },
    maxValue: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
    }
  },
  watch: {
    data() {
      this.draw();
    },
    startDate() {
      this.draw();
    },
    endDate() {
      this.draw();
    },
    colorMap() {
      this.draw();
    },
    dayIndex() {
      this.updateDayIndex(this.dayIndex);
    }
  },
  beforeDestroy() {
    this.$refs.chart.removeEventListener('mousemove', this.elMoveHandler);
    this.$refs.chart.removeEventListener('mouseout', this.outHandler);
  },
  mounted() {
    const el = this.$refs.chart;
    const ctx = el.getContext('2d');
    el.width = this.$el.clientWidth;
    el.height = this.$el.clientHeight;

    this.$refs.hover.style.display = 'none';
    this.$refs.select.style.display = 'none';
    this.$refs.tooltip.style.display = 'none';

    this.dateFormatter = getDateFormatter();

    this.chart = new Chart(ctx, {
        type: 'line',
        data: {
            datasets: [{
              data: [],
              pointRadius: 0,
              backgroundColor: `rgba(33, 150, 243, 0.8)`
            }],
        },
        options: {
            title: {
              display: false,
            },
            legend: {
              display: false,
            },
            maintainAspectRatio: false,
            animation: {
              duration: 0
            },
            tooltips: {
                mode: 'x-axis',
                enabled: false,
                custom: (tooltipModel) => {
                  if(tooltipModel.dataPoints) {
                    const dataPoint = tooltipModel.dataPoints[0];
                    this.$refs.tooltip.innerHTML = this.dateFormatter.format(new Date(dataPoint.label)) + ": " + Math.round(parseFloat(dataPoint.value));
                    this.$refs.tooltip.style.display = 'block';
                    const rect = this.$refs.tooltip.getBoundingClientRect();
                    const toLeft = dataPoint.x > this.$refs.chart.clientWidth / 2;
                    const chartHeight = this.chart.chartArea.bottom - this.chart.chartArea.top;
                    this.$refs.tooltip.style.top = `${Math.round(this.chart.chartArea.top + (chartHeight - rect.height) / 2)}px`;
                    this.$refs.tooltip.style.left = `${Math.round(toLeft ? (dataPoint.x - rect.width - 10) : (dataPoint.x + 10))}px`;
                  } else {
                    this.$refs.tooltip.style.display = 'none';
                  }
                }
            },
            scales: {
                xAxes: [{
                    type: 'time',
                    display: true,
                    bounds: 'data',
                    time: {
                      unit: 'month'
                    },
                    ticks: {
                      maxTicksLimit: 6,
                    }
                }],
                yAxes: [{
                    display: true,
                    ticks: {
                      maxTicksLimit: 6,
                      min: 0,
                    }
                }]
            }
        }
    });
    this.draw();
    el.addEventListener('mousemove', this.elMoveHandler);
    el.addEventListener('mouseout', this.outHandler);
    el.addEventListener('resize', this.resizeHandler);
  },
  methods: {
    resizeHandler() {
      this.draw();
    },
    outHandler() {
      this.$refs.hover.style.display = 'none';
      this.$refs.tooltip.style.display = 'none';
    },
    elMoveHandler(evt) {
      const element = this.chart.getElementsAtXAxis(evt)[0];
      if(element) {
        this.$refs.hover.style.display = 'block';
        this.$refs.hover.style.left = `${Math.round(element._view.x)}px`;
        this.$refs.hover.style.top = Math.floor(this.chart.chartArea.top) + "px";
        this.$refs.hover.style.height = Math.ceil(this.chart.chartArea.bottom - this.chart.chartArea.top) + "px";
      }
    },
    updateDayIndex(index) {
      const data = this.chart.getDatasetMeta(0).data;
      const item = data[index];
      this.selectedIndex = index;
      if(item) {
        this.$refs.select.style.display = 'block';
        this.$refs.select.style.left = `${Math.round(item._view.x - 1)}px`;
      }
    },
    updateSelect(evt) {
      const element = this.chart.getElementsAtXAxis(evt)[0];
      if(element) {
        this.$emit('select', element._index);
      }
    },
    calcMinMax() {
      let min = Infinity;
      let max = -Infinity;
      // calc y ticks min / max
      if(this.data) {
        for(const item of this.data) {
          if(item.t >= this.startDate && item.t <= this.endDate) {
            min = Math.min(item.y, min);
            max = Math.max(item.y, max);
          }
        }
      }
      return {
        min,
        max
      }
    },

    async draw() {
      const max = Math.ceil(this.calcMinMax().max);
      const step = niceNum(max / 4);
      const remainder = max % step;
      const yMax = max + step - remainder;
      this.chart.options.scales.yAxes[0].ticks.min = 0;
      this.chart.options.scales.yAxes[0].ticks.max = yMax;
      this.chart.options.scales.xAxes[0].ticks.min = this.startDate;
      this.chart.options.scales.xAxes[0].ticks.max = this.endDate;
      this.chart.data.datasets[0].label = this.name;
      this.chart.data.datasets[0].data = this.data;
      const days = calcDays(this.endDate, this.startDate);
      this.chart.options.scales.xAxes[0].time.unit = days < 100 ? 'day' : 'month';

      if(this.colorMap && !isNaN(this.maxValue) && !isNaN(yMax)) {
        const ctx = this.$refs.chart.getContext('2d');
        const gradient = ctx.createLinearGradient(0, this.chart.chartArea.bottom, 0, this.chart.chartArea.top);
        if(this.colorMap === "steps") {
          const steps = getSteps();
          let lastP = 0;
          for(const step of steps) {
            const c = step[0];
            const v = step[1];
            const p = Math.min(1, v / yMax);
            gradient.addColorStop(lastP, c);
            gradient.addColorStop(p, c);
            lastP = p;
          }
        } else {
          const sampleNum = 10;
          for(let i = 0; i < sampleNum; i++) {
            const v = i / (sampleNum - 1) * yMax;
            const p = Math.min(1, v / this.maxValue);
            const c = colormap(p, v, this.colorMap);
            gradient.addColorStop(p, `rgb(${c[0]},${c[1]},${c[2]})`);
          }
        }
        this.chart.data.datasets[0].backgroundColor = gradient;
      }

      this.chart.update();
      this.$refs.select.style.top = Math.floor(this.chart.chartArea.top) + "px";
      this.$refs.select.style.height = Math.ceil(this.chart.chartArea.bottom - this.chart.chartArea.top) + "px";
      console.log('draw', this.$refs.select.style.height);

      this.updateDayIndex(this.dayIndex);
    }
  }
}
</script>
