import { interpolateOranges, interpolateMagma } from 'd3-scale-chromatic';
import { color } from 'd3-color';

const steps = [
  ['#fbf8ca', 5],
  ['#fbef7e', 25],
  ['#fbb234', 50],
  ['#d13624', 100],
  ['#931315', 250],
  ['#661313', 500],
  ['#da0183', 1000]
];

export function getSteps() {
  return steps;
}

function getStep(v) {
  for(const step of steps) {
    if(v <= step[1]) {
      return color(step[0]);
    }
  }
  return color(steps[steps.length - 1][0]);
}

export function colormap(t, v, type) {
  let col;
  switch(type) {
    case 'oranges':
      col = color(interpolateOranges(t));
      break;
    case 'magma':
      col = color(interpolateMagma(t));
      break;
    default:
    case 'steps':
      col = getStep(v);
      break;
  }
  if(!col) {
    console.log(t, v, type);
  }
  return [col.r, col.g , col.b, 200];
}
