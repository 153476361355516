var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mapWrapper",staticClass:"wrapper"},[_c('Mapbox',{attrs:{"accessToken":"","map-options":{
      style: _vm.mapStyle,
      center: _vm.center,
      zoom: _vm.zoom,
      antialias: true,
      pitchWithRotate: false,
      doubleClickZoom: false,
      scrollZoom: false,
      touchZoomRotate: true,
      touchPitch: false,
      dragPan: true,
      dragRotate: false,
    },"nav-control":{
      show: true,
      position: 'top-right',
      options: {
        showCompass: false
      }
    }},on:{"map-load":_vm.onMapReady}}),_c('div',{staticClass:"info-btn p-shadow-3",class:_vm.infoVisible ? 'selected': '',on:{"click":function($event){_vm.infoVisible = !_vm.infoVisible}}},[_vm._v("?")]),_c('div',{staticClass:"info p-d-flex p-p3",class:_vm.infoVisible ? 'visible': ''},[_c('table',[_vm._m(0),_c('tr',[_c('td',[_c('LeftClick',{staticClass:"icon mouseicon"})],1),_c('td',[_vm._v("(De-)Select a Country / Region")])]),_c('tr',[_c('td',[_c('LeftClickPan',{staticClass:"icon"})],1),_c('td',[_vm._v("Pan Map")])]),_c('tr',[_c('td',[_c('ShiftScroll',{staticClass:"icon mouseicon"})],1),_c('td',[_vm._v("Zoom Map")])]),_vm._m(1),_c('tr',[_c('td',[_c('Tap',{staticClass:"icon"})],1),_c('td',[_vm._v("(De-)Select a Country / Region")])]),_c('tr',[_c('td',[_c('TouchPan',{staticClass:"icon"})],1),_c('td',[_vm._v("Pan Map")])]),_c('tr',[_c('td',[_c('PinchZoom',{staticClass:"icon"})],1),_c('td',[_vm._v("Zoom Map")])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"info-header",attrs:{"colspan":"2"}},[_vm._v("Mouse")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('td',{staticClass:"info-header",attrs:{"colspan":"2"}},[_vm._v("Touch")])])}]

export { render, staticRenderFns }