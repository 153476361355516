<template>
  <div id="app" class="p-d-flex p-flex-column">
    <Navbar/>
    <Card class="p-mt-4 p-mb-2 p-mx-2 p-shadow-5">
      <template #title>
        <slot name="title"></slot>
      </template>
      <template #content>
        <template v-if="loaded">
          <div class="p-d-flex-column p-flex-wrap p-ai-center">
            <slot name="options"></slot>
            <slot name="dayselect"></slot>
            <slot name="map"></slot>
            <div class="p-mt-3">
              <slot name="regiontitle"></slot>
            </div>
            <slot name="timeline"></slot>
            <slot name="legend"></slot>
          </div>
        </template>
        <template v-else>
          <div class="loading p-d-flex p-ai-center p-jc-center">
            <h2 class="loading-headline">Loading...</h2>
          </div>
        </template>
      </template>
    </Card>
  </div>
</template>
<style scoped>
  .loading {
    height: calc(100vh - 180px);
  }
  .loading-headline {
    flex: 0 0 auto;
  }
  .legend-card {
    position: absolute;
    top: 25px;
    left: 25px;
  }

  .p-slider-vertical {
    height: 14rem;
  }
  /deep/.p-card-body {
    height: 100%;
  }
  /deep/.p-card-content {
    height: 100%;
    padding: 0px;
  }
</style>
<script>
export default {
  name: 'LayoutSmall',
  props: {
    loaded: Boolean
  },
}
</script>
