<template>
  <div id="app" class="p-d-flex p-flex-column">
    <Navbar/>
    <div :style="{ flex: '1 0 auto', position: 'relative' }">
      <slot name="map"></slot>
      <template v-if="loaded">
        <Card class="legend-card">
          <template #content>
            <slot name="regiontitle"></slot>
            <slot name="options"></slot>
            <slot name="dayselect"></slot>
            <slot name="timeline"></slot>
            <slot name="legend"></slot>
          </template>
        </Card>
      </template>
      <template v-else>
        <div class="loading-wrapper">
          <div class="loading p-d-flex p-ai-center p-jc-center">
            <h2 class="loading-headline">Loading...</h2>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<style scoped>
  .loading-wrapper {
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .loading {
    height: 100%;
  }
  .loading-headline {
    flex: 0 0 auto;
    border-radius: 10px;
    background: #fff;
    padding: 15px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
  }
  .legend-card {
    position: absolute;
    top: 25px;
    left: 25px;
  }

  .p-slider-vertical {
    height: 14rem;
  }
  /deep/.p-card-body {
    height: 100%;
  }
  /deep/.p-card-content {
    height: 100%;
    padding: 0px;
  }

  #app {
    height: 100%;
  }
</style>
<script>
export default {
  name: 'LayoutWide',
  props: {
    loaded: Boolean
  },
}
</script>
