export default [
  {
    id: 'background',
    type: 'background',
    paint: {
      'background-color': '#bbccdd'
    }
  },
  {
    id: 'countries-fill',
    type: 'fill',
    source: 'countries',
    paint: {
      'fill-color': '#555555',
    }
  },
  {
    id: 'countries-line',
    type: 'line',
    source: 'countries',
    layout: {
      'line-join': 'round'
    },
    paint: {
      'line-color': '#ffffff',
      'line-width': [
        'interpolate', ['linear'], ['zoom'],
        1, 0,
        3, 0.2,
        4, 0.5,
        10, 1
      ],
      'line-opacity': [
        'interpolate', ['linear'], ['zoom'],
        1, 0,
        3, 0.5,
      ],
    }
  },
  {
    id: 'places-labels',
    type: 'symbol',
    source: 'places',
    'layout': {
      'text-padding': 15,
      'text-font': ['Open Sans Regular'],
      'text-field': ['get', 'label_en'],
      'text-size': ['interpolate', ['linear'], ['get', 'pop'],
        0, 8,
        1000000, 12,
      ],
      'symbol-sort-key': ['-', ['get', 'pop']]
    },
    'paint': {
      'text-color': '#ffffff',
      'text-halo-width': 1,
      'text-halo-color': 'rgba(0, 0, 0, 1)',
      'text-opacity': 1
    },
    'filter': ['>=', ['zoom'],
      ['interpolate', ['linear'], ['get', 'pop'],
        0, 4,
        50000,3,
        10000000, 1,
      ]
    ]
  },
  {
    id: 'countries-labels',
    type: 'symbol',
    source: 'labels',
    'layout': {
      'text-padding': [ 'interpolate', ['linear'], ['zoom'],
        1, 5,
        7, 15
      ],
      'text-font': ['Open Sans Regular'],
      'text-field': ['get', 'label_en'],
      'text-size': [ 'interpolate', ['linear'], ['zoom'],
        1, 12,
        7, 20
      ],
      'symbol-sort-key': ['get', 'sort']
    },
    'paint': {
      'text-color': '#ffffff',
      'text-halo-width': 1,
      'text-halo-color': 'rgba(0, 0, 0, 1)',
      'text-opacity': 0.5,
    },
    'filter': [ 'any',
      ['>=', ['zoom'], 6],
      // zoom < 6 && area > 5000
      ['all', ['>=', ['zoom'], 5], ['<', ['zoom'], 6], ['>', ['get', 'area'], 5000]],
      // zoom < 5 && area > 50000
      ['all', ['>=', ['zoom'], 2], ['<', ['zoom'], 5], ['>', ['get', 'area'], 50000]],
      // zoom < 3 && area > 1000000
      ['all', ['<', ['zoom'], 2], ['>', ['get', 'area'], 1000000]]
    ]
  },
]
