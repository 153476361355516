import Vue from 'vue';
import VueMq from 'vue-mq';
import registerPrimeVue from './registerPrimeVue.js';
import './assets/main.css';

export default function (Component, config) {
  Vue.config.productionTip = false;
  Vue.prototype.$location = window.location;
  Vue.use(VueMq, {
    breakpoints: {
      sm: 600,
      lg: Infinity
    }
  });

  registerComponents();
  registerPrimeVue();

  new Vue({
      mounted: () => {
        console.log('mounted top level app');
        document.dispatchEvent(new Event("x-app-rendered"));
      },
      render: h => h(Component, {

        props: config || {}
      })
  }).$mount('#app');
}

function registerComponents() {
  const requireComponent = require.context(
    // The relative path of the components folder
    './components',
    // Whether or not to look in subfolders
    true,
    // The regular expression used to match base component filenames
    /\.(vue)$/
  )

  requireComponent.keys().forEach(fileName => {
    // Get component config
    const componentConfig = requireComponent(fileName);

    const componentName = fileName
          .split('/')
          .pop()
          .replace(/\.\w+$/, '');


    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig
    );

  })
}
