<template>
  <div class="wrapper p-d-flex p-flex-wrap p-ai-center p-my-1">
    <label class="label p-mr-3 p-my-1">{{label}}:</label>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>
<style>
  .wrapper {
    flex: 1 1 auto;
  }
  .label {
    font-weight: 700;
    min-width: 120px;
  }
  .content {
    flex: 1 1 300px;
  }
</style>

<script>
export default {
  name: 'Field',
  props: {
    label: String
  }
}
</script>
