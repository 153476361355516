export function getDateFormatter() {
  return new Intl.DateTimeFormat(navigator.language , {
   dateStyle: "short",
  });
}


const formats = {
  "af-ZA": "yy/mm/dd",
  "am-ET": "d/m/yy",
  "ar-AE": "dd/mm/yy",
  "ar-BH": "dd/mm/yy",
  "ar-DZ": "dd-mm-yy",
  "ar-EG": "dd/mm/yy",
  "ar-IQ": "dd/mm/yy",
  "ar-JO": "dd/mm/yy",
  "ar-KW": "dd/mm/yy",
  "ar-LB": "dd/mm/yy",
  "ar-LY": "dd/mm/yy",
  "ar-mA": "dd-mm-yy",
  "ar-Om": "dd/mm/yy",
  "ar-QA": "dd/mm/yy",
  "ar-SA": "dd/mm/y",
  "ar-SY": "dd/mm/yy",
  "ar-TN": "dd-mm-yy",
  "ar-YE": "dd/mm/yy",
  "arn-CL": "dd-mm-yy",
  "as-IN": "dd-mm-yy",
  "az-Cyrl-AZ": "dd.mm.yy",
  "az-Latn-AZ": "dd.mm.yy",
  "ba-RU": "dd.mm.y",
  "be-BY": "dd.mm.yy",
  "bg-BG": "dd.m.yy",
  "bn-BD": "dd-mm-y",
  "bn-IN": "dd-mm-y",
  "bo-CN": "yy/m/d",
  "br-FR": "dd/mm/yy",
  "bs-Cyrl-BA": "d.m.yy",
  "bs-Latn-BA": "d.m.yy",
  "ca-ES": "dd/mm/yy",
  "co-FR": "dd/mm/yy",
  "cs-CZ": "d.m.yy",
  "cy-GB": "dd/mm/yy",
  "da-DK": "dd-mm-yy",
  "de-AT": "dd.mm.yy",
  "de-CH": "dd.mm.yy",
  "de-DE": "dd.mm.yy",
  "de-LI": "dd.mm.yy",
  "de-LU": "dd.mm.yy",
  "dsb-DE": "d. m. yy",
  "dv-mV": "dd/mm/y",
  "el-GR": "d/m/yy",
  "en-029": "mm/dd/yy",
  "en-AU": "d/mm/yy",
  "en-BZ": "dd/mm/yy",
  "en-CA": "dd/mm/yy",
  "en-GB": "dd/mm/yy",
  "en-IE": "dd/mm/yy",
  "en-IN": "dd-mm-yy",
  "en-Jm": "dd/mm/yy",
  "en-mY": "d/m/yy",
  "en-NZ": "d/mm/yy",
  "en-PH": "m/d/yy",
  "en-SG": "d/m/yy",
  "en-TT": "dd/mm/yy",
  "en-US": "m/d/yy",
  "en-ZA": "yy/mm/dd",
  "en-ZW": "m/d/yy",
  "es-AR": "dd/mm/yy",
  "es-BO": "dd/mm/yy",
  "es-CL": "dd-mm-yy",
  "es-CO": "dd/mm/yy",
  "es-CR": "dd/mm/yy",
  "es-DO": "dd/mm/yy",
  "es-EC": "dd/mm/yy",
  "es-ES": "dd/mm/yy",
  "es-GT": "dd/mm/yy",
  "es-HN": "dd/mm/yy",
  "es-mX": "dd/mm/yy",
  "es-NI": "dd/mm/yy",
  "es-PA": "mm/dd/yy",
  "es-PE": "dd/mm/yy",
  "es-PR": "dd/mm/yy",
  "es-PY": "dd/mm/yy",
  "es-SV": "dd/mm/yy",
  "es-US": "m/d/yy",
  "es-UY": "dd/mm/yy",
  "es-VE": "dd/mm/yy",
  "et-EE": "d.mm.yy",
  "eu-ES": "yy/mm/dd",
  "fa-IR": "mm/dd/yy",
  "fi-FI": "d.m.yy",
  "fil-PH": "m/d/yy",
  "fo-FO": "dd-mm-yy",
  "fr-BE": "d/mm/yy",
  "fr-CA": "yy-mm-dd",
  "fr-CH": "dd.mm.yy",
  "fr-FR": "dd/mm/yy",
  "fr-LU": "dd/mm/yy",
  "fr-mC": "dd/mm/yy",
  "fy-NL": "d-m-yy",
  "ga-IE": "dd/mm/yy",
  "gd-GB": "dd/mm/yy",
  "gl-ES": "dd/mm/y",
  "gsw-FR": "dd/mm/yy",
  "gu-IN": "dd-mm-y",
  "ha-Latn-NG": "d/m/yy",
  "he-IL": "dd/mm/yy",
  "hi-IN": "dd-mm-yy",
  "hr-BA": "d.m.yy.",
  "hr-HR": "d.m.yy",
  "hsb-DE": "d. m. yy",
  "hu-HU": "yy. mm. dd.",
  "hy-Am": "dd.mm.yy",
  "id-ID": "dd/mm/yy",
  "ig-NG": "d/m/yy",
  "ii-CN": "yy/m/d",
  "is-IS": "d.m.yy",
  "it-CH": "dd.mm.yy",
  "it-IT": "dd/mm/yy",
  "iu-Cans-CA": "d/m/yy",
  "iu-Latn-CA": "d/mm/yy",
  "ja-JP": "yy/mm/dd",
  "ka-GE": "dd.mm.yy",
  "kk-KZ": "dd.mm.yy",
  "kl-GL": "dd-mm-yy",
  "km-KH": "yy-mm-dd",
  "kn-IN": "dd-mm-y",
  "ko-KR": "yy-mm-dd",
  "kok-IN": "dd-mm-yy",
  "ky-KG": "dd.mm.y",
  "lb-LU": "dd/mm/yy",
  "lo-LA": "dd/mm/yy",
  "lt-LT": "yy.mm.dd",
  "lv-LV": "yy.mm.dd.",
  "mi-NZ": "dd/mm/yy",
  "mk-mK": "dd.mm.yy",
  "ml-IN": "dd-mm-y",
  "mn-mN": "y.mm.dd",
  "mn-mong-CN": "yy/m/d",
  "moh-CA": "m/d/yy",
  "mr-IN": "dd-mm-yy",
  "ms-BN": "dd/mm/yy",
  "ms-mY": "dd/mm/yy",
  "mt-mT": "dd/mm/yy",
  "nb-NO": "dd.mm.yy",
  "ne-NP": "m/d/yy",
  "nl-BE": "d/mm/yy",
  "nl-NL": "d-m-yy",
  "nn-NO": "dd.mm.yy",
  "nso-ZA": "yy/mm/dd",
  "oc-FR": "dd/mm/yy",
  "or-IN": "dd-mm-y",
  "pa-IN": "dd-mm-y",
  "pl-PL": "yy-mm-dd",
  "prs-AF": "dd/mm/y",
  "ps-AF": "dd/mm/y",
  "pt-BR": "d/m/yy",
  "pt-PT": "dd-mm-yy",
  "qut-GT": "dd/mm/yy",
  "quz-BO": "dd/mm/yy",
  "quz-EC": "dd/mm/yy",
  "quz-PE": "dd/mm/yy",
  "rm-CH": "dd/mm/yy",
  "ro-RO": "dd.mm.yy",
  "ru-RU": "dd.mm.yy",
  "rw-RW": "m/d/yy",
  "sa-IN": "dd-mm-yy",
  "sah-RU": "mm.dd.yy",
  "se-FI": "d.m.yy",
  "se-NO": "dd.mm.yy",
  "se-SE": "yy-mm-dd",
  "si-LK": "yy-mm-dd",
  "sk-SK": "d. m. yy",
  "sl-SI": "d.m.yy",
  "sma-NO": "dd.mm.yy",
  "sma-SE": "yy-mm-dd",
  "smj-NO": "dd.mm.yy",
  "smj-SE": "yy-mm-dd",
  "smn-FI": "d.m.yy",
  "sms-FI": "d.m.yy",
  "sq-AL": "yy-mm-dd",
  "sr-Cyrl-BA": "d.m.yy",
  "sr-Cyrl-CS": "d.m.yy",
  "sr-Cyrl-mE": "d.m.yy",
  "sr-Cyrl-RS": "d.m.yy",
  "sr-Latn-BA": "d.m.yy",
  "sr-Latn-CS": "d.m.yy",
  "sr-Latn-mE": "d.m.yy",
  "sr-Latn-RS": "d.m.yy",
  "sv-FI": "d.m.yy",
  "sv-SE": "yy-mm-dd",
  "sw-KE": "m/d/yy",
  "syr-SY": "dd/mm/yy",
  "ta-IN": "dd-mm-yy",
  "te-IN": "dd-mm-y",
  "tg-Cyrl-TJ": "dd.mm.y",
  "th-TH": "d/m/yy",
  "tk-Tm": "dd.mm.y",
  "tn-ZA": "yy/mm/dd",
  "tr-TR": "dd.mm.yy",
  "tt-RU": "dd.mm.yy",
  "tzm-Latn-DZ": "dd-mm-yy",
  "ug-CN": "yy-m-d",
  "uk-UA": "dd.mm.yy",
  "ur-PK": "dd/mm/yy",
  "uz-Cyrl-UZ": "dd.mm.yy",
  "uz-Latn-UZ": "dd/mm yy",
  "vi-VN": "dd/mm/yy",
  "wo-SN": "dd/mm/yy",
  "xh-ZA": "yy/mm/dd",
  "yo-NG": "d/m/yy",
  "zh-CN": "yy/m/d",
  "zh-HK": "d/m/yy",
  "zh-mO": "d/m/yy",
  "zh-SG": "d/m/yy",
  "zh-TW": "yy/m/d",
  "zu-ZA": "yy/mm/dd",
};

const formatsShort = {};

for(const key in formats) {
  const keyShort = key.split('-')[0];
  formatsShort[keyShort] = formats[key];
}

export function getLocaleDateString() {
  return formats[navigator.language] || formatsShort[navigator.language] || "dd/m/yy";
}
