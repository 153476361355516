<template>
  <nav id="navbar" class="navbar p-shadow-5 p-ai-center p-d-flex p-pl-4 p-pr-4">
    <Icon class="icon p-mr-2"/>
    <div class="icon-wrapper p-mr-5 p-py-3">Corona-Maps</div>
    <div class="spacer-burger"></div>
    <div class="p-d-flex p-ai-center p-my-2">
      <a @click="selected = !selected" class="burger-wrapper" :class="selected ? 'burger-wrapper-selected' : ''">&#xe91D;</a>
    </div>
    <div class="link-wrapper" :class="selected ? 'link-wrapper-open' : ''">
      <div class="p-d-flex p-ai-center" :class="currentItemClass('world')">
        <a href="world.html" class="link" :class="currentItemClass('world')">World Countries</a>
      </div>
      <div class="p-d-flex p-ai-center" :class="currentItemClass('germany')">
        <a href="germany.html" class="link" :class="currentItemClass('germany')">Germany Regions</a>
      </div>
      <div class="spacer"></div>
      <div class="p-d-flex p-ai-center" :class="currentItemClass('about')">
        <a href="about.html" class="link other" :class="currentItemClass('about')">About</a>
      </div>
      <div class="p-d-flex p-ai-center p-ml-0 p-mr-0" :class="currentItemClass('privacy')">
        <a href="privacy.html" class="link other" :class="currentItemClass('privacy')">Privacy</a>
      </div>
    </div>
  </nav>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import Icon from './Icon.vue';
import { currentLocation } from '../route.js';

export default {
  name: 'Navbar',
  components: {
    Icon
  },
  data() {
    return {
      selected: false
    }
  },
  computed: {
    currentLocation
  },
  methods: {
    currentItemClass(item) {
      return (this.currentLocation === item) ? 'currentItem' : '';
    }
  }
}
</script>
<style>
:root {
  --breakpoint-sm: 500px;
}
</style>

<style scoped>

.icon {
  height: 30px;
}
.navbar {
  background: #fff;
  font-size: 16pt;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-self: stretch;
  z-index: 100;
}

div.currentItem {
  background: #f3f3f3;
  height: 100%;
}

.icon-wrapper {
  font-size: 22pt;
}
.link-wrapper {
  font-family: "Open Sans Condensed Light";
  display: flex;
  align-items: center;
  align-self: stretch;
  flex: 1 0 auto;
}
.link {
  text-decoration: none;
  margin: 5px 10px;
  color: #999;
}
.link.currentItem {
  color: #333;
}
.link.other {
  color: #000;
  margin: 0px 20px;
}

.burger-wrapper {
  display: none;
  font-family: primeicons;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
  border:none;
  cursor: pointer;
  user-select: none;
}

.burger-wrapper-selected {
  background: rgb(239, 239, 239);
  border-radius: 5px;
}
.spacer-burger {
  display: none;
}
.spacer {
  flex: 1 1 auto;
}

@media screen and (max-width: 600px) {
  .burger-wrapper {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    padding: 10px;
  }
  .spacer-burger {
    display: block;
    flex: 1 0 auto;
  }
  .spacer {
    display: none;
  }

  .navbar {
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .link-wrapper {
    flex-basis: 100%;
    flex-direction: column;
    align-items: start;
    display: none;
    margin: 5px 0px;
  }
  .link-wrapper-open {
    display: flex;
  }
}
</style>
