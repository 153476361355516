<template>
  <div class="wrapper" ref="mapWrapper">
    <Mapbox
      accessToken=""
      :map-options="{
        style: mapStyle,
        center,
        zoom,
        antialias: true,
        pitchWithRotate: false,
        doubleClickZoom: false,
        scrollZoom: false,
        touchZoomRotate: true,
        touchPitch: false,
        dragPan: true,
        dragRotate: false,
      }"
      :nav-control="{
        show: true,
        position: 'top-right',
        options: {
          showCompass: false
        }
      }"

      @map-load="onMapReady"
    />
    <div @click="infoVisible = !infoVisible" class="info-btn p-shadow-3" :class="infoVisible ? 'selected': ''">?</div>
    <div class="info p-d-flex p-p3" :class="infoVisible ? 'visible': ''">
      <table>
        <tr>
          <td colspan="2" class="info-header">Mouse</td>
        </tr>
        <tr>
          <td><LeftClick class="icon mouseicon"/></td>
          <td>(De-)Select a Country / Region</td>
        </tr>
        <tr>
          <td><LeftClickPan class="icon"/></td>
          <td>Pan Map</td>
        </tr>
        <tr>
          <td><ShiftScroll class="icon mouseicon"/></td>
          <td>Zoom Map</td>
        </tr>
        <tr>
          <td colspan="2" class="info-header">Touch</td>
        </tr>
        <tr>
          <td><Tap class="icon"/></td>
          <td>(De-)Select a Country / Region</td>
        </tr>
        <tr>
          <td><TouchPan class="icon"/></td>
          <td>Pan Map</td>
        </tr>
        <tr>
          <td><PinchZoom class="icon"/></td>
          <td>Zoom Map</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import {MapboxLayer} from '@deck.gl/mapbox';
import {GeoJsonLayer} from '@deck.gl/layers';
import Pbf from 'pbf';
import geobuf from 'geobuf';
import LeftClick from './icons/LeftClick.vue';
import LeftClickPan from './icons/LeftClickPan.vue';
import PinchZoom from './icons/PinchZoom.vue';
import ShiftScroll from './icons/ShiftScroll.vue';
import Tap from './icons/Tap.vue';
import TouchPan from './icons/TouchPan.vue';

import { colormap } from '../colorMapUtils';
import mapStyle from './mapStyle';

export default {
  name: 'CoronaMap',
  components: {
    Mapbox,
    LeftClick,
    LeftClickPan,
    PinchZoom,
    ShiftScroll,
    Tap,
    TouchPan
  },
  props: {
    colorMap: {
      type: String,
      default: 'magma'
    },
    mode: {
      type: String,
      default: 'light'
    },
    selectedId: {
      type: Number,
      default: -1
    },
    center: Array,
    zoom: Number,
    attributionList: Array
  },
  data() {
    return {
      mapStyle,
      infoVisible: false
    }
  },
  created() {
    this.data = [];
    this.updateCycleFlag = false;
  },
  watch: {
    mode() {
      this.updateStyle();
    },
    selectedId() {
      this.updateMap();
    },
    colorMap() {
      this.updateMap();
    },
  },
  methods: {
    getScreenHeight() {
      return screen.height;
    },
    async onMapReady(map) {
      this.$emit('mapReady');

      this.shapeLayer = new MapboxLayer({
          type: GeoJsonLayer,
          id: 'landkreise-geojson',
          data: this.data,
          pickable: true,
          stroked: true,
          filled: true,
          extruded: false,
          getLineColor: (d) => {
            if(d.properties.id === this.selectedId) {
              if(this.mode === 'light') {
                return [ 0, 0, 0, 255 ];
              } else {
                return [ 255, 255, 255, 255 ];
              }
            } else {
              return [ 0, 0, 0, 0];
            }
          },
          getFillColor: (d) => {
            const t = d.properties.t;
            if(!isNaN(t)) {
              return colormap(t, d.properties.vR, this.colorMap);
            } else {
              return [0, 0, 0, 200];
            }
          },
          lineWidthMinPixels: 1,
          updateTriggers: {
            getFillColor: this.updateCycleFlag,
            getLineColor: this.selectedId
          },
          onClick: (info) => {
            if(info.object.properties.id !== this.selectedId) {
              this.$emit('select', info.object.properties);
            } else {
              this.$emit('select', null);
            }
          }
      });

      map.addLayer(this.shapeLayer, 'countries-line');
      // see https://github.com/visgl/deck.gl/issues/5564
      this.shapeLayer.deck.setProps({touchAction: 'unset'});

      // see https://github.com/mapbox/mapbox-gl-js/issues/2618
      map.on('touchstart', event => {
          const e = event.originalEvent;
          if (e && 'touches' in e) {
              if (e.touches.length > 1) {
                  map.dragPan.enable();
              } else {
                  map.dragPan.disable();
              }
          }
      });

      map.on("wheel", event => {
        if (event.originalEvent.shiftKey) { // Check if SHIFT key is pressed
          event.originalEvent.preventDefault(); // Prevent chrome/firefox default behavior
          if (!map.scrollZoom._enabled) {
            map.scrollZoom.enable(); // Enable zoom only if it's disabled
            map.scrollZoom.setWheelZoomRate(1/60);
          }
        } else {
          if (map.scrollZoom._enabled) map.scrollZoom.disable(); // Disable zoom only if it's enabled
        }
      });

      if(this.attributionList.length > 0) {
        map.addControl(new global.mapboxgl.AttributionControl({
          customAttribution: this.attributionList
        }));
      }
      this.map = map;
      await this.loadSources();
      this.updateStyle();
      this.updateMap();
    },
    async loadSources() {
      const remoteData = await Promise.all([
        fetch('data/countries.pbf'),
        fetch('data/labels.pbf'),
        fetch('data/places.pbf'),
      ]);

      const remoteBufferData = await Promise.all([
        remoteData[0].arrayBuffer(),
        remoteData[1].arrayBuffer(),
        remoteData[2].arrayBuffer(),
      ]);

      const countriesPbf = new Pbf(remoteBufferData[0]);
      const labelPbf = new Pbf(remoteBufferData[1]);
      const placesPbf = new Pbf(remoteBufferData[2]);
      this.map.getSource('countries').setData(geobuf.decode(countriesPbf));
      this.map.getSource('labels').setData(geobuf.decode(labelPbf));
      this.map.getSource('places').setData(geobuf.decode(placesPbf));
    },
    updateStyle() {
      if(this.map) {
        if(this.mode === 'light') {
          this.map.setPaintProperty('background', 'background-color', '#445566');
          this.map.setPaintProperty('countries-fill', 'fill-color', '#aaaaaa');
          this.map.setPaintProperty('countries-line', 'line-color', '#000000');
          this.map.setPaintProperty('places-labels', 'text-color', '#000000');
          this.map.setPaintProperty('places-labels', 'text-halo-color', '#cccccc');
          this.map.setPaintProperty('places-labels', 'text-halo-width', 2);
          this.map.setPaintProperty('countries-labels', 'text-color', '#000000');
          this.map.setPaintProperty('countries-labels', 'text-halo-color', '#cccccc');
          this.map.setPaintProperty('countries-labels', 'text-halo-width', 2);
          this.map.setPaintProperty('countries-labels', 'text-opacity', 1);
        } else {
          this.map.setPaintProperty('background', 'background-color', '#bbccdd');
          this.map.setPaintProperty('countries-fill', 'fill-color', '#555555');
          this.map.setPaintProperty('countries-line', 'line-color', '#ffffff');
          this.map.setPaintProperty('places-labels', 'text-color', '#ffffff');
          this.map.setPaintProperty('places-labels', 'text-halo-color', 'rgba(0, 0, 0, 1)');
          this.map.setPaintProperty('places-labels', 'text-halo-width', 1);
          this.map.setPaintProperty('countries-labels', 'text-color', '#ffffff');
          this.map.setPaintProperty('countries-labels', 'text-halo-color', 'rgba(0, 0, 0, 1)');
          this.map.setPaintProperty('countries-labels', 'text-halo-width', 1);
          this.map.setPaintProperty('countries-labels', 'text-opacity', 0.5);
        }

        if(this.shapeLayer) {
          // update the layer
          this.shapeLayer.setProps({
            updateTriggers: {
              getLineColor: this.selectedId + this.mode
            },
            getLineColor: (d) => {
              if(d.properties.id === this.selectedId) {
                if(this.mode === 'light') {
                  return [ 0, 0, 0, 255 ];
                } else {
                  return [ 255, 255, 255, 255 ];
                }
              } else {
                return [ 0, 0, 0, 0];
              }
            },
          });
        }
      }
    },
    updateMap() {
      this.updateCycleFlag = !this.updateCycleFlag;
      if(this.shapeLayer) {
        // update the layer
        this.shapeLayer.setProps({
            data: this.data,
            updateTriggers: {
              getFillColor: this.updateCycleFlag,
              getLineColor: this.selectedId + this.mode
            },
            getFillColor: (d) => {
              const t = d.properties.t;
              if(!isNaN(t)) {
                return colormap(t, d.properties.vR, this.colorMap);
              } else {
                return [0, 0, 0, 200];
              }
            },
        });
      }
    }
  }
}
</script>
<style>
#map .mapboxgl-canvas-container.mapboxgl-interactive {
  cursor: default !important;
}
#map .mapboxgl-canvas {
  cursor: default !important;
}
</style>
<style scoped>
.wrapper {
  width: 100%;
  height: 100%;
  min-height: 600px;
  position: relative;
  overflow:hidden;
}
#map {
  width: 100%;
  height: 100%;
  position: absolute;
}

.info-btn {
  z-index: 3;
  position: absolute;
  right: 11px;
  top: 75px;
  background: #fff;
  border-radius: 100%;
  width: 1.60rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  user-select: none;
  cursor: pointer;
  text-align: center;
}

.info-btn.selected {
  color: #fff;
  background: #777;
}

.info {
  z-index: 3;
  position: absolute;
  right: 40px;
  top: 10px;
  margin: 0px 10px;
  background: #fff;
  border-radius: 5px;
  padding: 10px 15px;
  display: none !important;
}
.info.visible {
  display: flex !important;
}


.icon {
  height: 2.5rem;
  width: auto;
  padding: 0px 0px;
}
.mouseicon {
  height: 2rem;
}
.info-header {
  margin: 10px 0px 10px 0px;
  width: 100%;
  text-align: center;
  padding-bottom: 15px;
  font-weight: bold;
  font-size: 16pt;
}

@media screen and (max-width: 600px) {
  .wrapper {
    min-height: 300px;
  }
  .icon {
    height: 1.5rem;
  }
  .mouseicon {
    height: 1rem;
  }
  .info-header {
    padding-bottom: 10px;
    font-weight: bold;
    font-size: 14pt;
  }
}



td {
  text-align: center;
}

.tooltip {
  background: #fff;
  padding: 8px;
  border-radius: 5px;
  position: absolute;
  pointer-events: none;
}
</style>
