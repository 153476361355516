'use strict'; // code generated by pbf v3.2.1

// Dataset ========================================

var Dataset = exports.Dataset = {};

Dataset.read = function (pbf, end) {
    return pbf.readFields(Dataset._readField, {ids: [], dates: [], groups: [], dataSets: [], data: []}, end);
};
Dataset._readField = function (tag, obj, pbf) {
    if (tag === 1) pbf.readPackedVarint(obj.ids, true);
    else if (tag === 2) pbf.readPackedVarint(obj.dates, true);
    else if (tag === 3) obj.groups.push(pbf.readString());
    else if (tag === 4) obj.dataSets.push(pbf.readString());
    else if (tag === 6) pbf.readPackedVarint(obj.data);
};
Dataset.write = function (obj, pbf) {
    if (obj.ids) pbf.writePackedVarint(1, obj.ids);
    if (obj.dates) pbf.writePackedVarint(2, obj.dates);
    if (obj.groups) for (var i = 0; i < obj.groups.length; i++) pbf.writeStringField(3, obj.groups[i]);
    if (obj.dataSets) for (i = 0; i < obj.dataSets.length; i++) pbf.writeStringField(4, obj.dataSets[i]);
    if (obj.data) pbf.writePackedVarint(6, obj.data);
};
