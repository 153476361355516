import 'primeflex/primeflex.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import PrimeVue from 'primevue/config';
import Vue from 'vue';

import Card from 'primevue/card';
import Divider from 'primevue/divider';

const components = {
  Card,
  Divider
}

export default function() {
  Vue.use(PrimeVue);

  for(const compName in components) {
    Vue.component(compName, components[compName]);
  }

}
