<template>
  <div class="legend-wrapper p-flex-row p-ai-stretch">
    <div class="p-d-flex p-mt-3">
      <span>0</span>
      <div :style="{flex: '1 0 auto'}"/>
      <span>{{maxValue}}</span>
    </div>
    <span v-if="!isNaN(selectedValue)" ref="tip" class="tip p-p-1 p-shadow-4">{{selectedValue}}</span>
    <canvas class="canvas" :style="{ width: barWidth + 'px', height: barHeight + 'px'}" ref="canvas" :height="barHeight"/>
    <DropDown class="p-d-flex" v-model="selectedColorMap" :options="colorMaps" optionLabel="label"/>
    <p class="p-text-right">All values are COVID-19 7-day incidence rates.</p>
  </div>
</template>
<style scoped>
  .canvas {
    border: 1px solid black;
    border-radius: 3px;
    box-sizing: content-box;
  }
  .selected {
    font-weight: bold;
    text-align: center;
    flex: 1 0 auto;
  }
  .tip {
    position: absolute;
    background: #fff;
    border-radius: 3px;
  }
</style>
<script>
import { colormap, getSteps } from '../colorMapUtils';
import DropDown from 'primevue/dropdown';

export default {
  name: 'Legend',
  props: {
    colorMap: {
      type: Object,
      default: () => ({ id: 'magma', label: 'Magma'})
    },
    barHeight: {
      type: Number,
      default: 30
    },
    maxValue: {
      type: Number
    },
    selectedValue: {
      type: Number,
      default: NaN
    },
    selectedName: {
      type: String,
    }
  },
  data() {
    return {
      colorMaps: [ { id: 'magma', label: 'Magma'}, { id: 'oranges', label: 'Orange'}, { id: 'steps', label: 'Steps' } ],
      barWidth: 100
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeListener);
  },
  created() {
      const userColorMap = localStorage.getItem('colormap');
      const colorMap = this.colorMaps.find(v => v.id === userColorMap);
      this.$emit('colormap', colorMap || this.colorMaps[0]);
  },
  computed: {
    selectedColorMap: {
      get() {
        return this.colorMap;
      },
      set(v) {
        this.$emit('colormap', v);
      }
    }
  },
  watch: {
    maxValue() {
      this.draw();
    },
    selectedValue() {
      this.draw();
    },
    colorMap() {
      this.draw();
    }
  },
  mounted() {
    window.addEventListener('resize', this.resizeListener);
    this.resizeListener();
  },
  methods: {
    resizeListener() {
      this.barWidth = this.$el.clientWidth - 2;
      this.draw();
    },
    async draw() {
      const canvas = this.$refs.canvas;
      if(canvas) {
        canvas.width = this.barWidth;
        const ctx = canvas.getContext('2d');
        let v = -1;
        if(this.colorMap.id === 'steps') {
          const steps = getSteps();
          const stepWidth = this.barWidth / steps.length;
          let i = 0;
          ctx.textBaseline = "middle";
          ctx.textAlign = "center";
          ctx.font = "8pt sans serif";
          for(const step of steps) {
            const stepBound = step[1];
            const lastStepBound = i === 0 ? 0 : steps[i - 1][1];
            let text = `${(i < steps.length - 1) ? '\u2264' :`>`}`;
            text+=(i < steps.length - 1) ? stepBound : lastStepBound;
            const textX = i * stepWidth + stepWidth / 2;
            const textY = this.barHeight / 2 + 1;
            ctx.fillStyle = step[0];
            ctx.fillRect(i * stepWidth, 0, stepWidth, this.barHeight);
            ctx.fillStyle = i < 3 ? '#000' : '#fff';
            ctx.fillText(text, textX, textY);
            if(i === 0 || this.selectedValue > lastStepBound) {
              v = (i * stepWidth) + Math.min(1, (this.selectedValue - lastStepBound) / (stepBound - lastStepBound)) * stepWidth;
            }
            i++;
          }
        } else {
          for(let i = 0; i < this.barWidth; i++) {
            const t = i / (this.barWidth - 1);
            const col = colormap(t, t * this.maxValue, this.colorMap.id);
            ctx.fillStyle = `rgba(${col[0]},${col[1]},${col[2]},1)`;
            ctx.fillRect(i, 0, 1, this.barHeight);
          }

          if(!isNaN(this.selectedValue)) {
            v = Math.round(this.selectedValue / this.maxValue * (this.barWidth - 1));
          }
        }
        if(v > -1) {
          ctx.fillStyle = `rgba(0, 0, 0 , 1)`;
          ctx.fillRect(v - 1, 0, 1, this.barHeight);
          ctx.fillRect(v + 1, 0, 1, this.barHeight);
          ctx.fillStyle = `rgba(255, 255, 255 , 1)`;
          ctx.fillRect(v, 0, 1, this.barHeight);

          await this.$nextTick();
          const tip = this.$refs.tip;
          tip.style.display = 'block';
          tip.style.top = `${canvas.offsetTop - tip.offsetHeight - 2}px`;
          tip.style.left = `${canvas.offsetLeft + v - tip.offsetWidth / 2}px`;
        } else {
          const tip = this.$refs.tip;
          if(tip) {
            tip.style.display = 'none';
          }
        }
      }
    }
  },
  components: {
    DropDown
  }
}
</script>
