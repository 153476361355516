//import RKIDataHolder from './RKIDataHolder';
export const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

export const calcDays = (firstDate, lastDate) => {
  return Math.round(Math.abs((firstDate - lastDate) / oneDay));
}

export function calcTimeline(dataHolder, {
  ewz,
  kreisId = -1,
  groupIds,
  startDate,
  endDate
}) {
  const dates = dataHolder.dates;
  const startDateIdx = dataHolder.getDateIdx(new Date(startDate));
  const endDateIdx = dataHolder.getDateIdx(new Date(endDate));
  // array of one day values, where all selected groups are summed
  const dayArray = new Uint32Array(endDateIdx - startDateIdx + 1);

  if(kreisId === -1) {
    // compute timeline for all kreise
    for(let idlIdx = 0; idlIdx < dataHolder.ids.length; idlIdx++) {
      for (let dateIdx = startDateIdx; dateIdx <= endDateIdx; dateIdx++) {
        for(const groupIdx of groupIds) {
          dayArray[dateIdx - startDateIdx]+= dataHolder.getItem(
            idlIdx,
            dateIdx,
            groupIdx,
            0
          );
        }
      }
    }
  } else {
    const idlIdx = dataHolder.getIdIdx(kreisId);

    for (let dateIdx = startDateIdx; dateIdx <= endDateIdx; dateIdx++) {
      let currentValue = 0;
      for(const groupIdx of groupIds) {
        currentValue+= dataHolder.getItem(
          idlIdx,
          dateIdx,
          groupIdx,
          0
        );
      }
      dayArray[dateIdx - startDateIdx] = (currentValue);
    }
  }

  // array of cumulated values of the last seven days
  // calculated with a moving window
  const sevenDayArray = [];

  // divide each 7day value by (ewz / 100000)
  // to get 7day incidence per 100000 inhabitants
  const ewzMul = 100000 / ewz;

  let currentValue = 0;
  for (let i = 0; i < dayArray.length; i++) {
    currentValue+=dayArray[i];
    currentValue-=(i > 6) ? dayArray[i - 7] : 0;
    sevenDayArray.push({ y:currentValue * ewzMul, t: new Date(dates[i + startDateIdx])});
  }
  return sevenDayArray;
}

export const calc7Day = (dataHolder, currentDate, groupIds) => {
  const currentValues = {};
  for(let idlIdx = 0; idlIdx < dataHolder.ids.length; idlIdx++) {
    const idl = dataHolder.ids[idlIdx];
    if(!currentValues[idl]) {
      currentValues[idl] = 0;
    }
  }

  // go back 7 days in the day hash
  for(let i = 0; i < 7; i++) {
    const dateIdx = dataHolder.getDateIdx(new Date(Number(currentDate) - oneDay * i));
    if(undefined !== dateIdx) {
      for(let idlIdx = 0; idlIdx < dataHolder.ids.length; idlIdx++) {
        const idl = dataHolder.ids[idlIdx];
        for(const groupIdx of groupIds) {
          currentValues[idl]+= dataHolder.getItem(
            idlIdx,
            dateIdx,
            groupIdx,
            0
          );
        }
      }
    }
  }
  return currentValues;
}

export const calcMaxValue = (dataHolder, kreise, groupIds, startDateIdx, endDateIdx) => {
  const days = 7;
  const currentValues = new Int32Array(dataHolder.ids.length);
  let max = -Infinity;

  const ewzValues = {};
  for(const item of kreise.features) {
    const kreisId = Number(item.properties.id);
    ewzValues[kreisId] = Number(item.properties.ewz) / 100000;
  }

  const startIdx = Math.max(0, startDateIdx - days);

  for(let curDateIdx = startIdx; curDateIdx <= endDateIdx; curDateIdx++) {
    // add values
    for(let idlIdx = 0; idlIdx < dataHolder.ids.length; idlIdx++) {
      for(const groupIdx of groupIds) {
        currentValues[idlIdx]+= dataHolder.getItem(
          idlIdx,
          curDateIdx,
          groupIdx,
          0
        );
      }
    }
    // remove values of day - 7
    const lastDateIdx = curDateIdx - days;
    if(lastDateIdx >= startIdx) {
      for(let idlIdx = 0; idlIdx < dataHolder.ids.length; idlIdx++) {
        for(const groupIdx of groupIds) {
          currentValues[idlIdx]-= dataHolder.getItem(
            idlIdx,
            lastDateIdx,
            groupIdx,
            0
          );
        }
      }
    }

    // calc max
    if(curDateIdx >= startDateIdx) {
      for(let i = 0; i < currentValues.length; i++) {
        const value = currentValues[i];
        const kreisId = dataHolder.ids[i];
        const ewz100 = ewzValues[kreisId];
        if(!isNaN(ewz100) && ewz100 !== 0) {
          max = Math.max(max, value / ewz100);
        }
      }
    }
  }
  return Math.ceil(max);
}

export function calcEWZAll(kreise) {
  let sum = 0;
  for(const item of kreise.features) {
    sum+=Number(item.properties.ewz);
  }
  return sum;
}
